import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const Seo = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <meta
        name="description"
        content="Not the official Batch website. Built for educational purposes, to study the design elements of a well designed website. Batch's official website can be found at batch.nz"
      ></meta>
      <title>
        {title} | {data.site.siteMetadata.title}
      </title>
    </>
  );
};

export default Seo;
