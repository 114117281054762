import React from "react";
import { li } from "./navigation.module.css";

const Navigation = () => {
  return (
    <ul
      className="d-flex col-8 justify-content-end"
      style={{ listStyle: "none" }}
    >
      <li>
        <a className="link" href="#do">
          Work history
        </a>
      </li>
      <li>
        <a className="link" href="#done">
          What I've built
        </a>
      </li>
      <li>
        <a className="link" href="#how">
          How I Do It
        </a>
      </li>
      <li>
        <a className="link" href="#contact">
          Contact Us
        </a>
      </li>
    </ul>
  );
};

export default Navigation;
