import React from "react";

export const ListBlock = () => {
  return (
    <ol className="row m-0">
      {doList.map((doItem) => (
        <Block
          key={doItem.title}
          title={doItem.title}
          content={doItem.content}
          period={doItem.period}
        />
      ))}
    </ol>
  );
};

const Block = ({ title, content, period }) => {
  return (
    <li
      className="col col-md-4 m-0 p-0 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "var(--primary)" }}
    >
      <div
        className="m-2 p-4"
        style={{
          backgroundColor: "var(--accent)",
          height: 240,
          borderRadius: 5,
          display: "grid",
        }}
      >
        <h3 style={{ fontSize: "1.5rem" }}>{title}</h3>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          {period}
        </p>
        <p className="m-0">{content}</p>
      </div>
    </li>
  );
};

const doList = [
  {
    title: "Web Developer and Consultant, Freelance",
    content:
      "Putting content online is simple, we do it everyday (email and social media). Building real connections online is hard, that's where I can help. Let's build a website that is genuine.",
    period: "May 2022 - Current",
  },
  {
    title: "Technical Account Manager, PlatformPlus",
    content:
      "Managed approx. thirty customer accounts, secured future revenue through customer re-contracting,provided tailored MSP products and solutions based on client needs.",
    period: "Mar 2021 - May 2022 (1 year 3 months)",
  },
  {
    title: "Implementation Manager, Dubanelle Enterprises Ltd",
    content:
      "Lead WMS (Warehouse Management System) implementation and selection for Dubanelle Enterprises Ltd (t/a Del Storage) and supporting business processes.",
    period: "Apr 2020 - Mar 2021 (1 year)",
  },
  {
    title: "Sea Kayak Guide, Rosco's Milford Kayaks",
    content:
      "Sharing the beautiful Milford Sound (Piopiotahi) with locals and travelers. Providing effective communication for engaging and safe trips.",
    period: "Nov 2019 - Mar 2020 (5 months)",
  },
  {
    title: "Technology Risk Advisor, KPMG",
    content:
      "Security Risk Assessments, Privacy Impact Assessments, and GITC (General IT Control) findings. Interviewed clients to understand information systems and flow for supporting evidence.",
    period: "Feb 2019 - Oct 2019 (9 months)",
  },
];
