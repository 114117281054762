import React from "react";
import { div, tHead, ul, li } from "./jobsDone.module.css";

// Breakpoints, 710px, 575px
export const JobsDone = () => {
  return (
    <div>
      <div className={tHead + " row m-0"}>
        <div className={div + " col p-1"}>Project</div>
        <div className={div + " col-6 d-none d-md-block p-1"}>Description</div>
        <div className={div + " col d-none d-sm-block p-1"}>Type</div>
        <div className={div + " col p-1"}>Technology</div>
      </div>
      <ul className={ul}>
        {works.map((work) => (
          <JobDone key={work.id} work={work} />
        ))}
      </ul>
    </div>
  );
};

const JobDone = ({ work }) => {
  return (
    <li className={li + " row m-0"}>
      <div className={div + " col p-1"}>
        <a href={work.link}>
          <p className="link">{work.title}</p>
        </a>
      </div>
      <div className={div + " col-6 d-none d-md-block p-1"}>
        {work.description.split(";").map((sentence) => (
          <p key={sentence}>{sentence}</p>
        ))}
      </div>
      <div className={div + " col d-none d-sm-block p-1"}>
        <p>{work.type}</p>
      </div>
      <div className={div + " col p-1"}>
        <p>{work.tech}</p>
      </div>
    </li>
  );
};

const works = [
  {
    id: "0",
    title: "Tippee",
    type: "Full Stack App",
    description:
      "A QR based tipping app. The worker signs up to receive tips through the app. This generates a unique barcode that they can then share with patrons. The patron can send tips by scanning the QR and selecting the amount they would like to tip. All without setting up an account.",
    tech: "React, Firebase Auth and DB, Webhooks, Stripe Payments",
    link: "https://tippee.emilio.co.nz",
  },
  {
    id: "1",
    title: "emilio.co.nz",
    type: "Website",
    description:
      "Recreating the design elements of batch.nz, a Christchurch based web design and development company, for a personal web portfolio.; This project is hosted on Netlify and updates when changes are pushed to the GitHub repo.",
    tech: "Gatsby, React, HTML and CSS",
    link: "./",
  },
  {
    id: "2",
    title: "Kiwi Birdle",
    type: "Game, SPA",
    description:
      "A take on Wordle where, instead of a secret word, a user attempts to guess the secret New Zealand bird.; This project is mobile friendly, is hosted on Github Pages, and uses a Dictionary API to validate user input.",
    tech: "Javascript, Dictionary API, HTML and CSS",
    link: "https://emcfadzean.github.io/",
  },
  {
    id: "3",
    title: "Notes and More",
    type: "Front-end Todo, SPA",
    description:
      "Connects to APIs to display a daily quote, mountains in a carousel, and Hamilton’s weather. Provides input to add and update goals. Rather than being particularly inspirational, it demonstrates code being structured more clearly than Kiwi Birdle, and uses more advanced front-end development tools: React and Redux.; This project is hosted on Netlify and updates when changes are pushed to the GitHub repo. ",
    tech: "React, Redux, APIs, HTML and CSS",
    link: "https://inspirational-homepage-250822.netlify.app/",
  },
];
