import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./contactForm.module.css";

const ContactForm = () => {
  return (
    <>
      <Form
        id="contactForm"
        name="contactForm"
        method="POST"
        data-netlify="true"
      >
        <p>Form</p>
        <input type="hidden" name="form-name" value="contactForm" />
        <Form.Group controlId="firstname" className="mb-3">
          <Form.Label>First name *</Form.Label>
          <Form.Control type="text" name="firstname" required />
        </Form.Group>
        <Form.Group controlId="lastname" className="mb-3">
          <Form.Label>Last name *</Form.Label>
          <Form.Control type="text" name="lastname" required />
        </Form.Group>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label>Email *</Form.Label>
          <Form.Control type="email" name="email" required />
        </Form.Group>
        <Form.Group controlId="companyname" className="mb-3">
          <Form.Label>Company name *</Form.Label>
          <Form.Control type="text" name="companyname" required />
        </Form.Group>
        <Form.Group controlId="message" className="mb-3">
          <Form.Label>How can we help? *</Form.Label>
          <Form.Control type="text" name="message" required />
        </Form.Group>
        <Button type="submit">Submit</Button>
      </Form>
    </>
  );
};

export default ContactForm;
