//Copysrc/components/layout.js: copy code to clipboard
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { container } from "./layout.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Layout = ({ pageTitle, children }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <div className={container}>
      <main>{children}</main>
      <footer className={container}>
        <p className="text-muted m-0">
          Created by Emilio, 2022. Design inspired by{" "}
          <a className="link" href="https://www.batch.nz/">
            batch.nz
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Layout;
